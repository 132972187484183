import React from 'react';

import './App.css';
import ProjectCard from '../widgets/ProjectCard.js';
import ContactBar from '../widgets/ContactInfo.js';
import GetPerson from '../Person.js';

function Work({ projects }) {
  let projectFilt = projects.filter((item) => item.featured);
  const s = { width: 'auto', margin: 'auto', display: 'flex', justifyContent: 'center' };
  return (
    <div className="App-section" style={{ height: 'auto' }}>
      <div className="App-anchor" id="work"></div>
      <h2>Featured Projects</h2>
      <div className="App-work">
        {projectFilt.map((project) => {
          return (
            <div key={project.key} style={s}>
              <ProjectCard project={project} />
            </div>
          );
        })}
      </div>
      <a className="menuItem1" style={{ margin: 'auto', width: 200 }} href="/all-projects">
        All Projects
      </a>
    </div>
  );
}

function IntroCard({ person, width, height }) {
  const small = width < 1.5 * height || width < 800;
  return (
    <div
      className="App-header"
      style={{
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: height
      }}
    >
      <div className="App-section" style={small ? { padding: '0px' } : { padding: '0px 200px' }}>
        <h1>{person.name}</h1>
        <p>{person.title}</p>
        <div className="hr"></div>
        <p className="App-subsection">{person.bio}</p>
      </div>
    </div>
  );
}

export function Home({ person, projects, width, height }) {
  return (
    <div className="App">
      <IntroCard person={person} width={width} height={height} />
      <main>
        <Work projects={projects} height={height} />
      </main>
      <div className="App-section fancybg">
        <div className="hr"></div>
        <ContactBar socials={GetPerson().socials} />
        <div className="hr"></div>
      </div>
    </div>
  );
}

export default Home;
