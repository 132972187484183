import React from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';

import Layout from './Layout.js';
import Home from './Home.js';
import { AllProjectPage } from './ProjectPage.js';
import TestSite from './TestSite.js';
import getProjectData from '../ProjectData.js';

const ProjectData = getProjectData();

// manually reset the scroll when entering the page
const ScrollToTop = (props) => {
  const location = useLocation();
  React.useEffect(() => {
    window.history.scrollRestoration = 'manual';
    document.getElementById('top').scrollIntoView();
  }, [location]);

  return <>{props.children}</>;
};

export default function App({ person }) {
  // resize first pannel on window resize
  const [width, setWindowWidth] = React.useState(window.innerWidth);
  const [height, setWindowHeight] = React.useState(window.innerHeight);
  const updateDimensions = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    setWindowWidth(width);
    setWindowHeight(height);
  };

  // update the stored width and height when window changes
  React.useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  // find all internal pages
  const projectsFilt = ProjectData.filter((p) => p.element);
  return (
    <BrowserRouter window={window}>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Layout width={width} height={height} />}>
            {/* route to home */}
            <Route
              index
              element={
                <Home person={person} projects={ProjectData} width={width} height={height} />
              }
            />
            {
              // do the magic to make each card a separate page
              projectsFilt.map((project) => {
                return (
                  <Route
                    key={project.key}
                    path={project.link}
                    element={<project.element width={width} height={height} />}
                  />
                );
              })
            }
            {/* super secret page ooOOOoo */}
            <Route path="testing" element={<TestSite />} />
            <Route
              path="all-projects"
              element={<AllProjectPage projects={ProjectData} width={width} height={height} />}
            />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}
