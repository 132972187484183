import React from 'react';
import './ContactInfo.css';

export function ContactInfo({ socials, className }) {
  return (
    <div className="contactInfo">
      {socials.map((soc) => {
        return (
          <div key={soc.key}>
            <a href={soc.link} target="_blank">
              <img className="thumbnail" src={soc.src} alt={soc.alt}></img>
            </a>
          </div>
        );
      })}
    </div>
  );
}

export default function ContactBar({ socials }) {
  return (
    <div className="App-section fancybg">
      <div className="App-anchor" id="contact"></div>
      <h3>Interested in working with me?</h3>
      <a
        style={{
          width: 'auto'
        }}
        className="menuItem"
        target="_blank"
        href="./res/documents/Macintyre Sunde Resume 2024.pdf"
      >
        Check out my Resume
      </a>
      <p className="main-text c">Find me on Github and LinkedIn, or reach out over Email!</p>
      <ContactInfo socials={socials} />
    </div>
  );
}
