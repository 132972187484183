import React from 'react';
import './ProjectCard.css';
import { Link } from 'react-router-dom';

export default function ProjectCard({ project }) {
  // card internals
  const cardElem = (
    <>
      <img src={project.image} alt={project.title}></img>
      <h3>{project.title}</h3>
      <div className="hr"></div>
      <p>{project.description}</p>
    </>
  );

  // if external link, go to a new page!
  const newTab = !Boolean(project.element);
  return (
    // choose where to the link
    newTab ? (
      <a href={project.link} className="projectCard" target="_blank" rel="noreferrer">
        {cardElem}
      </a>
    ) : (
      <Link to={project.link} className="projectCard">
        {cardElem}
      </Link>
    )
  );
}
