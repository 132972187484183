import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Home } from './Home.js';
import GetPerson from '../Person';
import getProjectData from '../ProjectData';

function getTargetPos(target, scrollContainer) {
  var offsetTop = 0;
  do {
    if (target === scrollContainer) break;
    if (!isNaN(target.offsetTop)) offsetTop += target.offsetTop;
  } while (target === target.offsetParent);

  return offsetTop;
}

function Layout({ width, height }) {
  // allow us to change the path
  let navigate = useNavigate();

  // handle page wrapping
  React.useEffect(() => {
    // find scroll container
    var target = document.getElementById('bottom');
    var scrollContainer = document.getElementById('top');
    do {
      scrollContainer = scrollContainer.parentNode;
      if (!scrollContainer) break;
      if (!scrollContainer.scrollTop) scrollContainer.scrollTop = 1;
    } while (scrollContainer.scrollTop === 0);

    // refresh page if we scroll to far
    const handleScroll = () => {
      if (getTargetPos(target, scrollContainer) <= window.scrollY) {
        navigate('/');
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [navigate]);

  return (
    <>
      <div id="top"></div>
      <Outlet />
      <div id="bottom"></div>
      <Home person={GetPerson()} projects={getProjectData()} width={width} height={height} />
    </>
  );
}

export default Layout;
