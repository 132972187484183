import Thesis from './pages/Thesis.js';
import NoiseTools from './pages/NoiseTools.js';

const ProjectData = [
  {
    key: 0,
    title: 'Shape Grammars for Architectural Reconstruction',
    description: 'How can we test hypotheses for the look and feel of ancient cities?',
    link: 'https://github.com/cosmicpotato137/ShapeGrammarLanguage',
    image: './res/images/projectCardProfiles/House1.PNG',
    featured: true,
    priority: 10
  },
  {
    key: 1,
    title: 'noise-tools',
    description: 'A Unity package for generating pseudorandom textures on the GPU',
    link: 'https://github.com/cosmicpotato137/noise-tools',
    image: './res/images/projectCardProfiles/noise-tools2.jpg',
    featured: true,
    priority: 10
  },
  {
    key: 2,
    title: 'Ray Tracing',
    description:
      'A raytracer written in C++ using OpenGL and GLSL. Developed for a compouter graphics course.',
    link: 'https://github.com/msunde137/raytracer',
    image: './res/images/projectCardProfiles/ray-tracer.JPG',
    newTab: true,
    featured: false,
    priority: 5
  },
  {
    key: 3,
    title: 'Nim',
    description: 'The mathematical game of Nim implemented for my intro CS class using pygame.',
    link: 'https://github.com/msunde137/nim',
    image: './res/images/projectCardProfiles/nim.JPG',
    newTab: true,
    featured: false,
    priority: 1
  },
  {
    key: 4,
    title: 'Particle Systems',
    description:
      'Particle system written in C++ using OpenGL. Developed for a compouter graphics course.',
    link: 'https://github.com/msunde137/particles',
    image: './res/images/projectCardProfiles/particles.JPG',
    newTab: true,
    featured: false,
    priority: 6
  },
  {
    key: 5,
    title: 'Games and Art',
    description: 'See my itch.io page for more games and technical art projects',
    link: 'https://cosmicpotato.itch.io/',
    image: './res/images/projectCardProfiles/incompleteCube.png',
    featured: false,
    priority: 7
  },
  {
    key: 6,
    title: 'Shader Engine',
    description: 'A rendering pipeline designed for writing and editing shaders in GLSL.',
    link: 'https://github.com/cosmicpotato137/shader-engine',
    image: './res/images/projectCardProfiles/madndelbrot.png',
    featured: true,
    priority: 10
  },
  {
    key: 7,
    title: 'Secret Santa',
    description:
      "Creates valid secret santa pairs given a set of sets of participants who can't be matched with each other.",
    link: 'https://secretsanta.cosmicpotato.tech',
    image: './res/images/amr-serag-7GLsZZ0qBIk-unsplash.jpg',
    featured: false,
    priority: 8
  },
  {
    key: 8,
    title: 'JavaScript Chatbot',
    description: 'A javascript chatbot app with speech-to-text capabilities.',
    link: 'https://github.com/cosmicpotato137/javascript-chatbot',
    image: './res/images/projectCardProfiles/LLAMACPP.png',
    featured: true,
    priority: 9
  },
  {
    key: 9,
    title: 'Stock Prediction',
    description: 'A colab notebook with some algorithmic trading experiments.',
    link: 'https://colab.research.google.com/drive/1I1MtrZT2BL2CemJ7DMP9VYaWj1eWE9Bn#scrollTo=nF0L-4k340Fg',
    image: './res/images/projectCardProfiles/stockPrice.png',
    featured: false,
    priority: 8
  },
  {
    key: 10,
    title: 'Arduino robot',
    description: 'An arduino robot CAD design',
    link: 'https://cad.onshape.com/documents/79981d31b8d25829be384bec/w/133a9860b636a6bd01127c65/e/c336610ad8cd210a46bdf647?renderMode=0&uiState=669c5ea6b32bef2413720bca',
    image: './res/images/projectCardProfiles/arduinoRobot.png',
    featured: true,
    priority: 9
  }
];

export default function getProjectData() {
  return ProjectData;
}
