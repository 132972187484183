import React, { useState } from 'react';

function TestSite() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Send the data to the PHP script
    const response = await fetch('https://cosmicpotato.tech/PHP/add_user.php', {
      method: 'POST',
      body: JSON.stringify({ firstName, lastName })
    });

    // Handle the response
    if (response.ok) {
      console.log('Person added successfully!');
      // Reset the form
      setFirstName('');
      setLastName('');
    } else {
      console.log('Failed to add person.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="firstName">First Name:</label>
        <input
          type="text"
          id="firstName"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="lastName">Last Name:</label>
        <input
          type="text"
          id="lastName"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
      <button type="submit">Add Person</button>
    </form>
  );
}

export default TestSite;
