const linkedin = './res/thumbnails/linkedin-w.png';
const gmail = './res/thumbnails/gmail-w.png';
const github = './res/thumbnails/github-w.png';
const profile = './res/images/profile.jpg';
const resume = './res/thumbnails/document.png';

const macintyre = {
  profile: profile,
  name: 'Macintyre Sunde',
  title: 'Software Developer and Creative',
  bio: 'Macintyre graduated from Haverford College in 2023 with a BA in Computer Science. They are now a software developer working on front end design, computer graphics and technical art projects. They are driven by the mission to tackle climate change with innovative technologies.',
  socials: [
    {
      key: 0,
      link: 'https://www.linkedin.com/in/macintyre-sunde-82b13b1a6/',
      src: linkedin,
      alt: 'LinkedIn'
    },
    {
      key: 1,
      link: 'https://github.com/cosmicpotato137',
      src: github,
      alt: 'GitHub'
    },
    {
      key: 2,
      link: 'mailto:imacsunde@gmail.com',
      src: gmail,
      alt: 'Gmail'
    }
  ]
};

export default function GetPerson() {
  return macintyre;
}
