import React from 'react';
import { Link } from 'react-router-dom';
import ProjectCard from '../widgets/ProjectCard';

export default function ProjectPage({ title, description, appParams }) {
  return (
    <div className="App">
      <div className="App-nav fancybg">
        <div className="navBar">
          <Link to="/" className="menuItem1">
            Home
          </Link>
          <h1 className="title" style={{ marginLeft: 'auto' }}>
            {title}
          </h1>
        </div>
        <div className="hr"></div>
      </div>
      <h2>Description</h2>
      <p className="deep-y main-text">{description}</p>
    </div>
  );
}

export function AllProjectPage({ projects, width, height }) {
  const small = width < 1.5 * height || width < 800;

  const f = {
    display: 'flex',
    flexDirection: small ? 'collumn' : 'row',
    justifyContent: 'center'
  };
  const s = {
    alignContent: 'right'
  };

  const displayList = (projList) => {
    // Clone the projList to avoid mutating the original array
    const sortedProjList = [...projList].sort((a, b) => a.priority - b.priority);

    return (
      <div style={s}>
        {sortedProjList.map((p) => (
          <ProjectCard key={p.key} project={p} />
        ))}
      </div>
    );
  };

  let content = <></>;
  if (small) {
    content = displayList(projects);
  } else {
    const p1 = projects.slice(0, projects.length / 3);
    const p2 = projects.slice(projects.length / 3, (projects.length / 3) * 2);
    const p3 = projects.slice((projects.length / 3) * 2, projects.length);

    content = (
      <>
        {displayList(p1)}
        {displayList(p2)}
        {displayList(p3)}
      </>
    );
  }

  return (
    <div>
      <h1>Here are my projects</h1>
      <div style={f}>{content}</div>
    </div>
  );
}
